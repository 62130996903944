<template>
  <div>
<OpenFillCardAddMain/>
  </div>
</template>

<script>
import OpenFillCardAddMain from '@/views/ProduceManagement/openManage/TabChild/OpenFillCardAddMain.vue'

export default {
    name:'OpenFillCardAdd',
    components: {
        OpenFillCardAddMain
    }
}
</script>

<style lang="scss" scoped>

</style>