<template>
  <div>
    <el-card> 
        <addHeader @submit="submit" @cancel="cancel" :statusShow="false"/>
       <el-form
        ref="addCardFormRef"
        :model="addCardForm"
        label-width="120px"
        :rules="addCardFormRules"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="产品编号：" size="small" prop="prod_no">
              <el-select
                clearable
                v-model.number="addCardForm.prod_no"
                placeholder="请选择产品编号"
                @change="prod_noChange"
                filterable
                @visible-change="get_all_podts_v2"
              >
                <el-option
                  v-for="item in options"
                  :key="item.podr_prod_id"
                  :label="item.podr_no + ' —— ' + item.prod_no"
                  :value="item.podr_prod_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单产品总数：" size="small" prop="prod_num">
              <el-input
                v-model="addCardForm.prod_num"
                :disabled="true"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item label="正在制作总数：" size="small" prop="temp_prod_num">
                     <el-input
                v-model="addCardForm.temp_prod_num"
                :disabled="true"
                clearable
              ></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="vd_mt_20">
          <el-col :span="8">
            <el-form-item label="数量：" size="small" prop="podr_prod_num">
              <el-input
                v-model="addCardForm.podr_prod_num"
                @input="
                  addCardForm.podr_prod_num = helper.keepTNum(
                    addCardForm.podr_prod_num
                  )
                "
                clearable
                @change="podrNumChange"
                  maxlength="10"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="刷卡卡号：" size="small" prop="brushCard">
              <el-input
                v-model="addCardForm.brushCard"
                @input="addCardForm.brushCard = helper.keepTNum(addCardForm.brushCard)"
                @change="brushCardKeyup"
                clearable
                maxlength="10"
                show-word-limit

              ></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="剩余制作总数：" size="small">
              <el-input
                v-model="surplusTotal"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
  
        </el-card>
  </div>
</template>

<script>
import addHeader from "@/views/component/addHeader.vue";
import { openAPI } from "@/api/modules/open";
import { podtAPI } from "@/api/modules/podt";
import { get, post } from "@/api/request";
export default {
  name: "OpenFillCardAddMain",
  components: {
    addHeader,
  },
  data () {
      return {
           addCardForm: {
        prod_no: "",
        order_no: "",
        podr_prod_num: "",
        prod_num: "",
        temp_prod_num:null,
        brushCard:''
      },
      surplusTotal:'',
      addCardFormRules: {
        prod_no: [
          { required: true, message: "请输入产品编号", trigger: "change" },
        ],
        order_no: [
          { required: true, message: "请输入订单编号", trigger: "change" },
        ],

        podr_prod_num: [
          { required: true, message: "请输入数量", trigger: "change" },
        ],
        brushCard:[
          { required: true, message: "请刷卡", trigger: "change" },

        ]
      },
      options: [],
      userInfo: {},
      }
  },
  created() {
    this.init();
  },
  methods: {
      init() {
      this.getUserInfo();
      this.get_all_podts_v2();
    },
    // 获取用户信息
    getUserInfo() {
      this.userInfo = this.$cookies.get("userInfo");
    },
    //提交
    submit() {
      this.$refs["addCardFormRef"].validate((valid) => {
        if (valid) {
          this.$confirm("确认提交?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if(this.addCardForm.podr_prod_num==0){
                return this.$message.warning('数量不能为零')
              }
              
              post(openAPI.add_mend_rfid, {
                stff_name: this.userInfo.stff_name,
                stff_id: this.userInfo.stff_id,
                user_id: this.userInfo.user_id,
                podr_id: this.addCardForm.order_no,
                podr_prod_id: this.addCardForm.prod_no,
                podr_prod_num: this.addCardForm.podr_prod_num,
                rfid_no:this.addCardForm.brushCard
              })
                .then((res) => {
                  if (res.data.code !== 0) {
                    return this.$message.error(res.data.msg);
                  }
                  this.$message.success(res.data.msg);
                  this.$refs.addCardFormRef.resetFields();
                  this.addCardForm.brushCard = ''
                  this.surplusTotal = ''
                })
                .catch((res) => {
                  let msg = res.data.msg;
                  this.$message.error(msg);
                });
            })
            .catch((res) => {});
        }
      });
    },
    //取消
    cancel() {
      this.$confirm("是否取消新增?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.jump("open_list");
          this.$message({
            type: "info",
            message: "已取消新增!",
          });
        })
        .catch((res) => {});
    },
    get_all_podts_v2() {
      get(podtAPI.get_all_podts_v2).then((res) => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.smg);
        }
        this.options = res.data.data;
      });
    },
    //刷卡
    brushCardKeyup() {
      // this.valiRfid()
      get(openAPI.get_rfid_by_no, { rfid_no: this.addCardForm.brushCard })
        .then((res) => {
          if (res.data.code == 0) {
            return this.$message.error("该卡已使用");
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    podrNumChange() {
      this.addCardForm.podr_prod_num = Number(this.addCardForm.podr_prod_num);
    },
    prod_noChange(val) {
        if(!val){
      this.addCardForm.prod_num = ''
      this.addCardForm.order_no = ''
      this.addCardForm.temp_prod_num = ''
       this.surplusTotal= ''
      return 
        }
      let arr = this.options.filter((item) => {
        return item.podr_prod_id == val;
      });
      this.addCardForm.prod_num = arr[0].prod_num;
      this.addCardForm.order_no = arr[0].podr_id;
      this.addCardForm.temp_prod_num = arr[0].temp_prod_num
      this.surplusTotal = arr[0].prod_num -  arr[0].temp_prod_num
    },
  },
  watch:{
      addCardForm: {
      handler(val) {
      if(val.temp_prod_num){
        if(val.prod_num-val.temp_prod_num<val.podr_prod_num){
          val.podr_prod_num = val.prod_num-val.temp_prod_num
           this.$message.warning('已自动切换到最大个数')
        }
        }
      },
      deep: true,
    },
  }
};
</script>

<style scoped>
.active {
  color: red;
}
.vd_mt_20 {
  margin-top: 20px;
}
</style>