import topic from '../topic'
export const openAPI = {
    get_rfids: topic.openTopic + '/get_rfids',
    add_rfid: topic.openTopic + '/add_rfid', //批量开卡
    get_rfid_by_no: topic.openTopic + '/get_rfid_by_no', //判读卡是否已使用
    add_mend_rfid: topic.openTopic + '/add_mend_rfid', //补卡
    get_rfid_by_id: topic.openTopic + '/get_rfid_by_id', // 回显
    destroy_rfid_by_ids: topic.openTopic + '/destroy_rfid_by_ids', // 删除



    
    
}